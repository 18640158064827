import user from 'src/service/user'
export default (axios, baseUrl) => {
  axios.defaults.withCredentials = true
  axios.defaults.crossDomain = true
  axios.defaults.baseURL = baseUrl
  axios.interceptors.request.use((config) => {
    const token = user.getToken()
    if (token) {
      config.headers.common['Authorization'] = token
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })
}
