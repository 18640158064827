<template lang="pug">
  .ListView
    van-list(
      v-model="loading"
      :finished="finished"
      :finished-text="empty"
      :error.sync="error"
      loading-text="数据加载中"
      error-text="请求失败，点击重新加载"
      @load="loadMore",
      :immediate-check="immediate"
    )
      slot(v-for="(item, index) in rowDetail",:row="item", :index="index")
</template>

<script>
import axios from 'axios'
export default {
  name: 'ListView',
  props: {
    url: {
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 10
    },
    immediate: {
      type: Boolean,
      default: true
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: true
    },
    method: {
      type: String,
      default: 'get'
    },
    filter: {
      type: Function,
      default (arr) {
        return arr
      }
    },
    queryParams: {
      type: Object,
      default () {
        return {}
      }
    },
    empty: {
      type: String,
      default: '没有更多了'
    }
  },
  data () {
    return {
      error: false,
      finished: false,
      params: {},
      rowDetail: [],
      currentPage: 1,
      loading: false,
      query: null
    }
  },
  watch: {
    url (val) {
      if (val) {
        this.load()
      }
    }
  },
  methods: {
    loadMore () {
      if (this.autoLoad) {
        this.reload()
      }
    },
    async request () {
      let res
      try {
        if (this.method === 'get') {
          res = await axios.get(this.url, {params: this.params})
        } else {
          res = await axios[this.method](this.url, this.params)
        }
        if (res) {
          const total = res.data.total
          if (!res.data.rows) {
            this.rowDetail.push(...this.filter(res.data))
            this.finished = true
          } else {
            this.rowDetail.push(...this.filter(res.data.rows))
            this.currentPage = this.currentPage + 1
            if (this.rowDetail.length < total) {
              this.finished = false
            } else {
              this.finished = true
            }
          }
          this.loading = false
          this.$emit('success', res.data)
        }
      } catch (e) {
        this.error = true
        console.log(e)
      }
    },
    reset () {
      this.rowDetail = []
      this.params.page = 1
      this.currentPage = 1
    },
    // TODO: 设置请求信息
    reload (query = this.query, method = this.method) {
      let params = {}
      if (this.pagination) {
        params.rows = this.pageSize
        params.page = this.currentPage
      } else {
        params.rows = 10 // 驱动后端发很多很多过来?
      }
      this.query = query
      Object.assign(params, query)
      this.params = params
      this.request()
    },
    // TODO: 初始化
    load (query = this.query, method = this.method) {
      this.reset()
      this.reload(query, method)
    }
  },
  mounted () {
    this.query = this.queryParams
  },
  computed: {},
  components: {}
}
</script>

<style scoped>
</style>
