import Vue from 'vue'
import {
  Button,
  Skeleton,
  Form,
  CellGroup,
  Field,
  Cell,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Stepper,
  Loading,
  Switch,
  Tab,
  Tabs,
  Popup,
  Picker,
  Dialog,
  Toast,
  List,
  DatetimePicker,
  Popover,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Notify,
  Area,
  ActionSheet,
  Image,
  Calendar
} from 'vant'

Vue.use(Button)
Vue.use(Skeleton)
Vue.use(Form)
Vue.use(CellGroup)
Vue.use(Field)
Vue.use(Cell)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Stepper)
Vue.use(Loading)
Vue.use(Switch)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(List)
Vue.use(Popover)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Notify)
Vue.use(Area)
Vue.use(ActionSheet)
Vue.use(Image)
Vue.use(Form)
Vue.use(Calendar)

Vue.prototype.$messageBox = Dialog
Vue.prototype.$alert = (message) => {
  Dialog.alert({
    title: '提示',
    message: message,
    theme: 'default',
    confirmButtonColor: '#26a2ff'
  })
}
Vue.prototype.$confirm = (options) => {
  return Dialog.confirm(options)
}
Vue.prototype.$toast = Toast
Vue.prototype.$success = (message = '操作成功') => {
  Toast({
    message,
    icon: 'success'
  })
}
let loading
Vue.prototype.$vantLoading = (message = '加载中') => {
  loading = Toast.loading({
    message,
    duration: 0,
    forbidClick: true
  })
}
Vue.prototype.$vantLoadingClose = () => {
  loading && loading.clear()
}
