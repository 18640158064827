import { areaList } from '@vant/area-data'

export default {
  // TODO: 解析二维码  筛查二维码生成规则：身份证+性别编码+出生日期+“0”+姓名（姓名改为唯一码） 44221119931023404X2198907070a1074948567019290624
  parseQrCode (result) {
    let source = result.split(',')
    let birthStr = source[0].substring(19, 27)
    let res = {}
    res.birth = Number(new Date(`${birthStr.substring(0, 4)}-${birthStr.substring(4, 6)}-${birthStr.substring(6, 8)}`).getTime())
    res.sex = Number(source[0].substring(16, 17))
    res.id = source[1]
    res.id32 = source[0].substring(29).replace('#', '')
    res.schoolType = Number(source[2])
    res.planType = Number(source[3])
    res.screeningPlanId = source[4]
    res.siteCode = source[5]
    return res
  },
  getRegionName (code) {
    if (areaList.province_list[code]) return areaList.province_list[code]
    if (areaList.city_list[code]) return areaList.city_list[code]
    if (areaList.county_list[code]) return areaList.county_list[code]
  }
}
