module.exports = {
	"default": {
		"token": "/business/token",
		"captcha": "captcha?captchaId="
	},
	"development": {
		"swagger": "/v2/api-docs?group=management",
		"host": "https://uss.api.dev.eyesws.com"
	},
	"debug": {
		"host": "https://uss.api.dev.eyesws.com"
	},
	"test": {
		"host": "//uss.api.test.eyesws.com"
	},
	"production": {
		"host": "https://uss.api.eyesws.com"
	}
};