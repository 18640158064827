module.exports = [
	{
		"name": "Home",
		"path": "/",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "VisualDocument",
		"path": "/VisualDocument",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "HealthReport",
		"path": "/HealthReport",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "CheckDetail",
		"path": "/CheckDetail",
		"file": "HealthReport/CheckDetail",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "HospitalList",
		"path": "/HospitalList",
		"file": "HospitalList/HospitalList",
		"meta": {
			"requiresAuth": true,
			"label": "机构列表"
		}
	},
	{
		"name": "HospitalDetail",
		"path": "/HospitalDetail",
		"file": "HospitalList/HospitalDetail",
		"meta": {
			"requiresAuth": true,
			"label": "机构主页"
		}
	},
	{
		"name": "HospitalDepartment",
		"path": "/HospitalDepartment",
		"file": "HospitalList/HospitalDepartment",
		"meta": {
			"requiresAuth": true,
			"label": "机构科室列表"
		}
	},
	{
		"name": "WorkSchedulingList",
		"path": "/WorkSchedulingList",
		"file": "WorkSchedulingList/WorkSchedulingList",
		"meta": {
			"requiresAuth": true,
			"label": "科室排班列表"
		}
	},
	{
		"name": "RegistrationOrderDetail",
		"path": "/RegistrationOrderDetail",
		"file": "WorkSchedulingList/RegistrationOrderDetail",
		"meta": {
			"requiresAuth": true,
			"label": "挂号详情"
		}
	},
	{
		"name": "ReportList",
		"path": "/ReportList",
		"file": "ReportList/ReportList",
		"meta": {
			"requiresAuth": true,
			"label": "检查报告列表"
		}
	},
	{
		"name": "RegistrationOrderPay",
		"path": "/RegistrationOrderPay",
		"file": "WorkSchedulingList/RegistrationOrderPay",
		"meta": {
			"requiresAuth": true,
			"label": "挂号订单详情"
		}
	},
	{
		"name": "PatientCardList",
		"path": "/PatientCardList",
		"file": "PatientCardList/PatientCardList",
		"meta": {
			"requiresAuth": true,
			"label": "就诊卡列表"
		}
	},
	{
		"name": "RegistrationList",
		"path": "/RegistrationList",
		"file": "RegistrationList/RegistrationList",
		"meta": {
			"requiresAuth": true,
			"label": "挂号列表"
		}
	},
	{
		"name": "AddAppointmentRecord",
		"path": "/AddAppointmentRecord",
		"file": "AddAppointmentRecord/AddAppointmentRecord",
		"meta": {
			"requiresAuth": true,
			"label": "添加预约登记"
		}
	},
	{
		"name": "DocDetail",
		"path": "/DocDetail",
		"file": "HealthReport/DocDetail",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "MedicalRecordDetail",
		"path": "/MedicalRecordDetail",
		"file": "HealthReport/MedicalRecordDetail",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "VisionReport",
		"path": "/VisionReport",
		"file": "VisualDocument/VisionReport",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "User",
		"path": "/User",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "BindingAccount",
		"path": "/BindingAccount",
		"meta": {
			"requiresAuth": true,
			"label": "绑定账号"
		}
	},
	{
		"name": "ArchivalUsersList",
		"path": "/ArchivalUsersList",
		"meta": {
			"requiresAuth": true,
			"label": "档案用户列表"
		}
	},
	{
		"name": "ArchivalUsersIndex",
		"path": "/ArchivalUsersIndex",
		"file": "ArchivalUsersList/ArchivalUsersIndex",
		"meta": {
			"requiresAuth": true,
			"label": "档案用户主页"
		}
	},
	{
		"name": "ArchivalUsersReport",
		"path": "/ArchivalUsersReport",
		"file": "ArchivalUsersList/ArchivalUsersReport",
		"meta": {
			"requiresAuth": true,
			"label": "档案数据页面"
		}
	},
	{
		"name": "BindingDataUser",
		"path": "/BindingDataUser",
		"meta": {
			"requiresAuth": true,
			"label": "绑定用户"
		}
	},
	{
		"name": "AdvisoryService",
		"path": "/AdvisoryService",
		"meta": {
			"requiresAuth": true,
			"label": "绑定账号"
		}
	},
	{
		"name": "HistoryReport",
		"path": "/HistoryReport",
		"meta": {
			"requiresAuth": true,
			"label": "历史报告"
		}
	},
	{
		"name": "VisionChart",
		"path": "/VisionChart",
		"meta": {
			"requiresAuth": true,
			"label": "近视趋势"
		}
	},
	{
		"name": "QuestionnaireInvestigation",
		"path": "/QuestionnaireInvestigation",
		"meta": {
			"requiresAuth": true,
			"label": "问卷调查"
		}
	},
	{
		"name": "CommonDiseasesQuestionnaireFill",
		"path": "/CommonDiseasesQuestionnaireFill",
		"meta": {
			"requiresAuth": true,
			"hideNav": true,
			"label": "常见病问卷调查"
		}
	},
	{
		"name": "Test",
		"path": "/Test",
		"meta": {
			"requiresAuth": true,
			"label": "测试"
		}
	},
	{
		"name": "HealthQuestionnaireFill",
		"path": "/HealthQuestionnaireFill",
		"meta": {
			"requiresAuth": true,
			"hideNav": true,
			"label": "儿童青少年近视调查问卷"
		}
	},
	{
		"name": "HealthQuestionnaireAnalysis",
		"path": "/HealthQuestionnaireAnalysis",
		"meta": {
			"requiresAuth": true,
			"hideNav": true,
			"label": "儿童青少年近视调查问卷分析"
		}
	},
	{
		"name": "OralHealthCheckFill",
		"path": "/OralHealthCheckFill",
		"meta": {
			"hideNav": true,
			"label": "口腔健康检查及窝沟封闭知情同意书"
		}
	},
	{
		"name": "ScreeningStudentRegistration",
		"path": "/ScreeningStudentRegistration",
		"meta": {
			"hideNav": true,
			"label": "学生筛查报名"
		}
	}
];