/**
 *
 微信登录
     页面有ticket
     -> 获取token
     -> 有
     -> 无 -> 点击登录的时候带上ticket
 帐号登录
  输入帐号
    -> 换取token
*/
import isNil from 'lodash.isnil'
import store from 'store'
import { bizMainHello, bizUserLoginByTicket, cmMainSystemInfo, bizHospitalList } from 'src/api'
import config from 'src/config'
import qs from 'qs'
import axios from 'axios'
import * as wechat from 'src/service/wechat'

const TOKEN_KEY = 'USS_ACCESS_TOKEN'
const CACHE_TICKET_KEY = 'CACHE_TICKET_KEY'
export default {
  userInfo: null,
  token: store.get(TOKEN_KEY),
  wxRegistUrl: null,
  platformBaseUrl: null,
  cacheTicket: null,
  aliyunConfig: null,
  siteCode: null,
  appletOriginalIdList: {
    slzc: null, // 视力自测小程序原始id
    jdzs: null // 建档助手小程序原始id
  },
  hospitalList: null,
  setInfo (info) {
    this.userInfo = info
  },
  isGuest () {
    return !this.hasToken()
  },
  getInfo () {
    return this.userInfo
  },
  getSiteCode () {
    return this.siteCode
  },
  clearInfo () {
    this.userInfo = null
  },
  getToken () {
    return this.token
  },
  setToken (token) {
    store.set(TOKEN_KEY, token)
    this.token = token
  },
  hasToken () {
    return !isNil(this.getToken())
  },
  clearToken () {
    this.token = null
    store.remove(TOKEN_KEY)
  },
  setWxRegistUrl (wxRegistUrl) {
    this.wxRegistUrl = wxRegistUrl || ''
  },
  setPlatformBaseUrl (platformBaseUrl) {
    this.platformBaseUrl = platformBaseUrl
  },
  setAliyunConfig (aliyunConfig) {
    this.aliyunConfig = aliyunConfig
  },
  setSiteCode (siteCode) {
    this.siteCode = siteCode
  },
  setAppletOriginalId (data) {
    this.appletOriginalIdList.slzc = data.originalId1
    this.appletOriginalIdList.jdzs = data.originalId2
  },
  setHospitalList (data) {
    this.hospitalList = data
  },
  getHospitalList () {
    return this.hospitalList
  },
  getAppletOriginalId (key) {
    return this.appletOriginalIdList[key] || null
  },
  getPlatformBaseUrl () {
    return this.platformBaseUrl
  },
  getTicket () {
    const url = window.location.href
    // eslint-disable-next-line
    const result = /wcoa_ticket=([^&|\?]*)/g.exec(url)
    if (result) {
      return result[1]
    }
    return null
  },
  wechatLogin () {
    // const href = window.location.href.replace('#','wx/#')
    const href = window.location.href
    const cacheTicket = window.sessionStorage.getItem(CACHE_TICKET_KEY)
    let result = this.wxRegistUrl.replace('P_RETURN_URL', encodeURIComponent(encodeURIComponent(href)))
    if (cacheTicket) {
      result = result.replace('P_TICKET', cacheTicket)
    } else {
      result = result.replace('P_TICKET', '')
    }
    // eslint-disable-next-line
    window.location.href = result.replace(/(\?|&)wcoa_ticket=[^&|\?]*/g, '')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  },
  isEyevision () {
    return this.siteCode === 'eyevision'
  },
  isYhjwyk () {
    return this.siteCode === 'yhjwyk'
  },
  isFssmxyk () {
    return this.siteCode === 'fssmxyk'
  },
  isCdhxyk () {
    return this.siteCode === 'cdhxyk'
  },
  isFsgyyj () {
    return this.siteCode === 'fsgyyj'
  },
  isHnsrmyy () {
    return this.siteCode === 'hnsrmyy'
  },
  isJsfkpt () {
    return this.siteCode === 'jsfkpt'
  },
  isXlyk () {
    return this.siteCode === 'xlyk'
  },
  isSzsjyyk () {
    return this.siteCode === 'szsjyyk'
  },
  isSmfy () {
    return this.siteCode === 'smfy'
  },
  isJjfybjy () {
    return this.siteCode === 'jjfybjy' || this.siteCode === 'jjfy'
  },
  isXmlsc () {
    return this.siteCode === 'xmlsc'
  },
  isXyyk () {
    return this.siteCode === 'xyyk'
  },
  async initTokenByTicket () {
    const ticket = this.getTicket()
    if (!ticket) {
      return
    }
    try {
      const requestParams = {}
      requestParams['ticket'] = ticket // ticket 必填
      const res = await bizUserLoginByTicket(requestParams)
      if (res.data) {
        this.setToken(res.data.access_token)
        await wechat.init()
        window.sessionStorage.removeItem(CACHE_TICKET_KEY)
      } else {
        if (!this.hasToken()) {
          window.sessionStorage.setItem(CACHE_TICKET_KEY, ticket)
        }
      }
    } catch (error) {
      throw error
    } finally {
      // eslint-disable-next-line
      window.location.hash = window.location.hash.replace(/(\?|&)wcoa_ticket=[^&|\?]*/g, '')
    }
  },
  async accountLogin (username, password) {
    try {
      const res = await axios.post(`${config.token}`, qs.stringify({ username, password }))
      this.setToken(res.data.access_token)
    } catch (error) {
      throw error
    }
  },
  async hello () {
    try {
      const res = await bizMainHello()
      const systemInfo = await cmMainSystemInfo()
      this.setPlatformBaseUrl(res.data.platformBaseUrl)
      this.setWxRegistUrl(res.data.wxRegistUrl)
      this.setAliyunConfig(res.data.aliyunConfig)
      this.setAppletOriginalId(res.data)
      let siteCode
      if (process.env.VUE_APP_ENV === 'development' || process.env.VUE_APP_ENV === 'debug') {
        siteCode = window.localStorage.getItem('testSiteCode') || 'aws'
      } else {
        siteCode = systemInfo.data.siteCode
      }
      this.setSiteCode(siteCode)
      const hospitalListReq = await bizHospitalList()
      this.setHospitalList(hospitalListReq.data)
      return res.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
