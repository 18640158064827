<template lang="pug">
  .NavBar
    van-tabbar(v-model="selected",:fixed="true",style="height:45px")
      van-tabbar-item(name="Home",@click.native="$router.push({name: 'Home'})")
        template(#icon="props")
          Icon(:name="`shouye${props.active ? '-xuanzhong' : ''}`")
        span 主页
      van-tabbar-item(v-if="user.getHospitalList().length > 0 && $route.name === 'VisionReport'"
        name="HospitalList"
        @click.native="$router.push({path: 'HospitalList'})")
        template(#icon="props")
          Icon(:name="`yiyuanguahao${props.active ? '-xuanzhong' : ''}`")
        span 医院挂号
      van-tabbar-item(v-if="$store.state.tabbar.showAppointment" name="AddAppointmentRecord",@click.native="gotoAppointment()")
        template(#icon="props")
          Icon(:name="`yuyue2${props.active ? '-xuanzhong' : ''}`")
        span 预约复查
      van-tabbar-item(
        v-if="menuList && menuList.length > 0 && !user.isCdhxyk()"
        name="Others"
        @click.native="toggleMenu()"
        style="position: relative;"
        :class="{'is-selected': showMenu}"
      )
        div.menu-container(v-show="showMenu" ref="menuRef" :style="{top: '-' + menuHeight + 'px'}")
          div.menu-item(v-for="menu in menuList.slice(1)" :key="menu.id" @click.stop="!menu.children ? openUrl(menu.toUrl) : menu.showChildren = !menu.showChildren")
            div.p2.text-scroll
              span {{ menu.name }}
            div.menu-children-item.p2.pl3.text-scroll(v-if="menu.children && menu.showChildren" v-for="children in menu.children" :key="children.id" @click.stop="openUrl(children.toUrl)")
              span {{ children.name }}
        template(#icon="props")
          Icon(:name="`qita${showMenu ? '-xuanzhong' : ''}`")
        span {{ menuList[0].name || '其他' }}
</template>

<script>
import user from 'src/service/user'
export default {
  name: 'NavBar',
  props: {
  },
  data () {
    return {
      selected: 'home',
      menuHeight: 0,
      showMenu: false,
      user,
      open: true
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (val) {
          this.selected = val.name
        }
      },
      deep: true
    }
  },
  computed: {
    menuList () {
      return this.$store.state.menu.menuList
    }
  },
  methods: {
    openUrl (url) {
      window.open(url, '_blank')
    },
    toggleMenu () {
      if (this.menuList.length === 1) {
        this.openUrl(this.menuList[0].toUrl)
      } else {
        this.showMenu = !this.showMenu
        this.selected = this.$route.name
      }
    },
    gotoAppointment () {
      let params = {
        screeningPlanStudentId: this.$store.state.tabbar.id,
        screeningPlanId: this.$store.state.tabbar.screeningPlanId
      }
      this.$router.push({name: 'AddAppointmentRecord', query: params})
    }
  },
  updated () {
    if (this.$refs.menuRef && this.$refs.menuRef.clientHeight) this.menuHeight = this.$refs.menuRef.clientHeight
  }
}
</script>

<style scoped>
.menu-container {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 12%), 0 1px 6px 0 rgb(0 0 0 / 12%);
  min-width: 150px;
  text-align: left;
}
.menu-item {
  font-size: 16px;
  color: #303133;
  border-bottom: 1px solid #E4E4E4;
}
.menu-children-item {
  font-size: 14px;
  color: #303133;
}
.menu-container span:active {
  color: #26a2ff;
}
.text-scroll {
  max-width: 7.5em;
  overflow-x: scroll;
  white-space: nowrap;
}
</style>
