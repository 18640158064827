import {bizMainWxWebConfig} from 'src/api'
import wx from 'weixin-js-sdk'

let isInit = false
export const isSupport = () => {
  const ua = window.navigator.userAgent.toLowerCase()
  const match = ua.match(/MicroMessenger/i)
  if (match && match[0] === 'micromessenger') {
    return true
  } else {
    return false
  }
}

export const init = () => {
  return new Promise((resolve, reject) => {
    if (isInit) {
      resolve()
      return
    }
    bizMainWxWebConfig().then(({data}) => {
      wx.config({
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        debug: false,
        jsApiList: ['scanQRCode', 'previewImage', 'chooseImage', 'openLocation'],
        openTagList: ['wx-open-launch-weapp']
      })
      wx.error(function (e) {
        alert('微信js-sdk配置错误,错误原因：' + e.errMsg)
        isInit = false
        reject(new Error(e.errMsg))
      })
      isInit = true
      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}

export const scanQRCode = async (desc = '请扫描二维码', needResult = 1, scanType = ['qrCode', 'barCode']) => {
  if (!isSupport()) {
    throw new Error('请使用微信扫码')
  }
  await init()
  return new Promise((resolve, reject) => {
    wx.scanQRCode({
      desc,
      needResult,
      scanType,
      success: function (res) {
        resolve(res.resultStr)
      },
      error: function (res) {
        if (res.errMsg.indexOf('function_not_exist') > 0) {
          reject(new Error('版本过低请升级'))
        } else {
          reject(new Error(res.errMsg))
        }
      }
    })
  })
}

export const closeWindow = () => {
  if (isSupport()) {
    // eslint-disable-next-line
    window.WeixinJSBridge.invoke('closeWindow', {}, function (resp) {
      if (resp.err_msg === 'close_window:ok') {
        return Promise.resolve(resp)
      } else {
        return Promise.reject(new Error(resp))
      }
    })
  }
}

export const impagePireview = async (current, urls = []) => {
  if (isSupport()) {
    await init()
    wx.previewImage({
      current: current || urls[0],
      urls
    })
  }
}

export const openLocation = async ({longitude = 113.323561, latitude = 23.09535, name = '广绣大厦', address = '爱唯视总部', scale = 14}) => {
  if (isSupport()) {
    await init()
    wx.openLocation({
      latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
      name: name, // 位置名
      address: address, // 地址详情说明
      scale: 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
      infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
    })
  }
}
