<template lang='pug'>
  .PopupTi
    van-popover(v-model='showPop' trigger='click' placement="bottom")
      template(#reference)
        Icon.ml1.primary-color(name="tishi")
      div.box
        slot
</template>

<script>
export default {
  name: 'PopupTip',
  data () {
    return {
      showPop: false
    }
  }
}
</script>

<style scoped>
.PopupTip {
  position: relative;
  display: inline-block;
}
.box{
  width: 260px;
  border-radius: 8px;
  padding: 10px;
  background-color: rgba(0,0,0,.5);
  color: #fff;
}
</style>
<style>
.van-popover__content {
  margin-left: 60px;
}
</style>
