// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import user from 'src/service/user'
import utils from 'src/utils'
import 'src/@eyevision/basecss/index.css'
import 'assets/icon_new/iconfont.css'
import 'assets/icon_new/iconfont.js'
import 'assets/icon/iconfont.css'
import 'assets/icon/iconfont-color.js'

import 'src/boot/importVantui'
import 'src/boot/importComponent'
import 'assets/style/color.css'
import 'assets/style/base.css'
import axiosHandle from 'src/boot/axiosHandle'
import axios from 'axios'
import axiosBigNumber from 'src/@eyevision/axios-bignum-plugin'
import axiosErrorHander from 'src/@eyevision/axios-error'
import { Dialog } from 'vant'
import * as api from 'src/api'
import config from 'src/config'
import 'assets/style/reboot.css'
import constant from 'src/const'
import 'src/filters'
import eventBus, { FORCE_UPDATE_APP } from 'src/event'
import _ from 'lodash'

if (!window.Promise) {
  window.Promise = require('promise')
}
window.document.title = '健康平台'
Vue.prototype.$isDev = process.env.VUE_APP_ENV === 'development'
Vue.prototype.$isDebug = process.env.VUE_APP_ENV === 'debug'
Vue.prototype.$const = constant
Vue.prototype.$utils = utils
Vue.prototype.$_ = _
Vue.config.ignoredElements = ['wx-open-launch-weapp']
axiosBigNumber(axios)
axiosHandle(axios, config.host)
axiosErrorHander(axios, (errorDetail) => {
  const gotoLogin = () => {
    user.clearToken()
    eventBus.$emit(FORCE_UPDATE_APP)
  }
  if (errorDetail.status === 403) {
    if (process.env.VUE_APP_ENV !== 'development') {
      gotoLogin()
    }
  } else if (errorDetail.status === 401) {
    gotoLogin()
  } else {
    Dialog.alert({
      message: errorDetail.message || errorDetail.tip
    })
  }
})
if (process.env.NODE_ENV === 'development') {
  axios.interceptors.request.use((config) => {
    // console.log(config)
    config.headers.common['Eyevision'] = 'aws'
    return config
  }, function (error) {
    return Promise.reject(error)
  })
}

Vue.config.productionTip = false
Vue.use(require('vue-progressbar'))
Vue.use(require('vue-moment'))
// api.setDomain(config.host)
Vue.prototype.$api = api

// eslint-disable-next-line
Array.prototype.toBinInt = function () {
  let binary = 0
  for (let item of this) {
    binary += item
  }
  return binary
}
// eslint-disable-next-line
Number.prototype.toBinArray = function () {
  const value = parseInt(this).toString(2)
  let result = []
  for (let i = 0; i < value.length; i++) {
    const v = parseInt(value[i])
    if (v === 1) {
      result.push(Math.pow(2, value.length - i - 1))
    }
  }
  return result
}

const initApp = () => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
  })
}

const initErrorPage = () => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    components: { App: () => import('pages/NetworkError/NetworkError') },
    template: '<App/>'
  })
}

const boot = async () => {
  try {
    await user.hello()
    await user.initTokenByTicket()
    initApp()
  } catch (error) {
    console.error(error)
    initErrorPage()
  }
}

boot()
