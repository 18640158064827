import JSONbig from 'json-bigint'
export default (axios, storeAsString = true) => {
  axios.defaults.transformResponse = [function (data, content) {
    if (content['content-type'] && content['content-type'].indexOf('/json') !== -1) {
      if (data) {
        return JSONbig.parse(data, {storeAsString})
      }
    }
    return data
  }]
}
