import { SET_APPOINTMENT_QUERY, CLEAR_APPOINTMENT_QUERY } from 'src/store/mutation-types'
const state = {
  showAppointment: false,
  appointmentQuery: {
    studentName: null,
    screeningPlanStudentId: null,
    screeningPlanId: null
  }
}
const mutations = {
  [SET_APPOINTMENT_QUERY] (state, { flag, params }) {
    state.showAppointment = flag
    state.appointmentQuery = params
  },
  [CLEAR_APPOINTMENT_QUERY] (state) {
    state.showAppointment = false
    state.appointmentQuery = {
      studentName: null,
      screeningPlanStudentId: null,
      screeningPlanId: null
    }
  }
}
const actions = {
}
const getters = {
}
export default {
  state,
  mutations,
  actions,
  getters
}
