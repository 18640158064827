import {
  SET_REPORT_MENU,
  CLEAR_REPORT_MENU
} from 'src/store/mutation-types'
const state = {
  menuList: null
}
const mutations = {
  [SET_REPORT_MENU] (state, params) {
    state.menuList = params
  },
  [CLEAR_REPORT_MENU] (state) {
    state.menuList = null
  }
}
const actions = {
}
const getters = {
}
export default {
  state,
  mutations,
  actions,
  getters
}
