import Vue from 'vue'
import _ from 'lodash'
// import date from 'element-ui/src/utils/date'
// import time from 'src/utils/time'
import constant from 'src/const/constant.yaml'
import moment from 'moment'
Vue.filter('money', (value) => {
  if (_.isNil(value)) {
    return ''
  }
  if (value === 0) {
    return '免费'
  }
  return `￥${value.toFixed(2)}`
})

Vue.filter('truncate', (value, len = 30) => {
  if (_.isNil(value)) {
    return ''
  }
  if (value.length > len) {
    value = value.slice(0, len) + '...'
    return value
  } else {
    return value
  }
})

Vue.filter('mask', (value, start, count) => {
  if (_.isEmpty(value)) {
    return ''
  }
  const transValue = value.split('')
  const len = count + start
  for (let i = start; i < len; i++) {
    transValue[i] = '*'
  }
  return transValue.join('')
})
Vue.filter('age', (value) => {
  if (_.isNil(value)) {
    return ''
  }
  return `${value}岁`
})

Vue.filter('sex', (sex) => {
  if (_.isNil(sex)) {
    return ''
  }
  if (sex === constant.SEX.MALE.value) {
    return constant.SEX.MALE.label
  } else if (sex === constant.SEX.FEMALE.value) {
    return constant.SEX.FEMALE.label
  }
})

Vue.filter('dateAge', (value, full = false) => {
  let timeStr = ''
  let nowTime = moment().valueOf()
  let age = Vue.moment(nowTime).diff(value, 'years')
  let month = ''
  if (full) {
    timeStr = Vue.moment(value).format('YYYY-MM-DD')
    if (age <= 0) {
      month = Vue.moment(value).get('month')
      return `${timeStr}(0岁${month}个月)`
    } else {
      age = age + '岁'
      return `${timeStr}(${age})`
    }
  }
  return `${age}岁`
})

Vue.filter('date', (value, formatter = 'yyyy-MM-dd') => {
  if (_.isNil(value)) {
    return ''
  }
  return moment(value).format('YYYY-MM-DD')
})

// Vue.filter('time', (value, formatter = 'yyyy-MM-dd HH:mm:ss') => {
//   if (_.isNil(value)) {
//     return ''
//   }
//   return date.format(value, formatter)
// })

Vue.filter('empty', (value) => {
  if (_.isNil(value) || value === '') {
    return '--'
  } else {
    return value
  }
})
