const statusTip = {
  '403': '没有权限,请重新登录',
  '401': '登录超时,请重新登录',
  '500': '服务器报错',
  '400': '参数错误'
}
export default (axios, errorCb) => {
  axios.interceptors.response.use((response) => {
    return response
  }, (e) => {
    const response = e.response
    const errorDetail = {}
    if (response) {
      if (response.status) {
        errorDetail.status = response.status
        errorDetail.tip = statusTip[response.status.toString()]
      }
      if (response.data && response.data.message) {
        errorDetail.message = response.data.message
      } else {

      }
    } else {
      errorDetail.message = e.message
    }
    errorCb && errorCb(errorDetail, e)
    return Promise.reject(e)
  })
}
